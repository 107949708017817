@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
body {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

[multiple],
[type="date"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
input:where(:not([type])),
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* background-color: #fff; */
  /* border-color: #6b7280; */
  border-width: 1px;
  /* border-radius: 0; */
  /* padding: .5rem .75rem; */
  /* font-size: 1rem; */
  /* line-height: 1.5rem; */
  /* --tw-shadow: 0 0 #0000; */
}

/* Disabled these password styles
[type="password"] {
  font-size: 32px;
  line-height: 20px;
}

[type="password"]::placeholder {
  font-size: 14px;
  line-height: 20px;
  transform: translateY(-7px)
} */

[type="checkbox"],
[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  /* -webkit-print-color-adjust: exact; */
  /* print-color-adjust: exact; */
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  flex-shrink: 0;
  /* height: 1rem; */
  /* width: 1rem; */
  color: #2563eb;
  /* background-color: #fff; */
  /* border-color: #6b7280; */
  border-width: 1px;
  /* --tw-shadow: 0 0 #0000; */
}
[type="checkbox"]:checked,
[type="radio"]:checked {
  border-color: #0000;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}
[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
}
[type="checkbox"]:focus,
[type="radio"]:focus {
  outline: 2px solid #0000;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}
[type="checkbox"]:checked:focus,
[type="checkbox"]:checked:hover,
[type="checkbox"]:indeterminate,
[type="radio"]:checked:focus,
[type="radio"]:checked:hover {
  border-color: #0000;
  background-color: currentColor;
}

.placeholder-tracking-normal::placeholder {
  letter-spacing: normal;
}

/**
 * Hide the password reveal button in Microsoft Edge.
 */
::-ms-reveal {
  display: none;
}

/**
 * Reset the default state for the Microsoft Map control.
 */
.MicrosoftMap * {
  box-sizing: content-box;
}
